import React, { useEffect } from "react";
import "./../App.scss";
import {useDispatch, useSelector} from "react-redux";
import Node from "./node/node";
import DynamicHeadContent from "./context-menu/block/modules/DynamicHeadContent";
import {PageNode} from "../types/PageNode";
import {RootState} from "../store";
import { setMovementStatus } from "../reducers/movementSlice";
import { CustomToast } from "./node/toast/CustomToast";
import { changePublishStatus, setCopiedContent } from "../reducers/app";
import { UpgradePlanModal } from "./node/toast/UpgradePlanModal";
import { setPublishModal, setShowUpgradeModal } from "../reducers/modalSlice";
import { PublishModal } from "./node/toast/PublishModal";

interface StylesProps {
}

const NodeContainer: React.FC<StylesProps> = () => {
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const links = useSelector((state: RootState) => state.appStorage.links);
    const previewState = useSelector((state:RootState) => state.appStorage.preview)
    const publishStatus = useSelector((state: RootState) => state.appStorage.publishStatus)
    const {isCompleted} = publishStatus
    const showUpgradeModal = useSelector((state: RootState) => state.modal.showUpgradeModal)
    const publishModal = useSelector((state: RootState) => state.modal.publishModal)
    const isCopiedContent = useSelector((state: RootState) => state.appStorage.isCopiedContent)

    const dispatch = useDispatch()

    useEffect(() => {
        if(nodes && nodes[0].customStyleMobile?.display === "grid"){
            dispatch(setMovementStatus({isMobile: true}))
        }else{
            dispatch(setMovementStatus({isMobile: false}))
        }
    }, [nodes])

    return (
        <>
            <div id="mainBlock">
                <div>
                    {nodes.map((node: PageNode) => (
                        <Node isPreviewState={previewState} key={node.id} node={node} />
                    ))}
                    {links.map((link: any, index: any) => (
                        <DynamicHeadContent key={index} path={link} />
                    ))}
                </div>
            </div>
            <CustomToast 
             isShow={isCompleted}
             setShow={() => dispatch(changePublishStatus({isLoading: false, isCompleted: false}))}
             message="Published!" 
             background="bg-success-light" 
             text="text-white"
             isSeenTick={true}
            />
            <CustomToast 
             isShow={isCopiedContent}
             setShow={() => dispatch(setCopiedContent(false))}
             message="Copied!" 
             background="bg-primary" 
             text="text-white"
            />
            <UpgradePlanModal 
             show={showUpgradeModal}
             onCancel={() => dispatch(setShowUpgradeModal(false))}
             onConfirm={() => window.location.href = `${process.env.REACT_APP_NEW_ADMIN}/payment`}
             title="Upgrade Your Plan to Keep Creating"
             description="To expand the functionality and continue creating projects, pages, or sharing content, please upgrade your subscription."
             confirmText="Upgrade My Plan"
            />
            <PublishModal show={publishModal.isActive} onCancel={() => dispatch(setPublishModal({isActive: false, pageId: null}))}/>
        </>
    );
}

export default NodeContainer;