interface IMeQrStorageData {
    storageSize: number; 
    usedStorageSize: number
}

const updateMeQrStorage = async (userName: string) => {
    if(window.top !== window.self) {
        try {
           await fetch(`${process.env.REACT_APP_MEQR_ADDRESS}/entry/constructor/user/file_size`, {
                method: "POST",
                body: JSON.stringify({ email: userName }),
            })
        } catch (err) {
            console.error(err)
        }
    }
}

const getMeQrStorageSize = async (userName: string): Promise<IMeQrStorageData | null> => {
    if(window.top !== window.self) {
        try {
            const response = await fetch(
              `${process.env.REACT_APP_MEQR_ADDRESS}/entry/file/user/email/storage_size`,
              {
                method: "POST",
                body: JSON.stringify({ email: userName }),
              }
            );
            const data = await response.json();
            return data as IMeQrStorageData;
        } catch (err) {
            console.error("Error fetching storage size:", err);
            return null;
        }
    }
}

export {
    updateMeQrStorage,
    getMeQrStorageSize,
    IMeQrStorageData
}