import {createSlice} from "@reduxjs/toolkit";
import {User} from "../graphql/types";
import {RequestPageType} from "../types/RequestPageType";
import {PageNode} from "../types/PageNode";

type TPublishStatus = {
    isLoading: boolean
    isCompleted: boolean
}

interface AppState {
    isMobileDisplayMode: boolean,
    isMobileViewDimension: boolean,
    user: User,
    isExportState: boolean,
    isPublishPageState: boolean,
    showHiddenBlocks: boolean,
    preview: boolean
    links: any[],
    title: string,
    type: RequestPageType|null,
    pageId: any
    isAdvancedMode: boolean
    isReadOnlyMode: boolean
    screenShotNodeId: string,
    newNode: PageNode,
    initPublishPage: boolean
    publishStatus: TPublishStatus
    isCopiedContent: boolean
    isClearElements: boolean
}

const initialState: AppState = {
    isMobileDisplayMode: false,
    isMobileViewDimension: false,
    user: null,
    isExportState: false,
    isPublishPageState: false,
    showHiddenBlocks: false,
    preview: false,
    links: [],
    title: 'No name',
    type: null,
    pageId: undefined,
    isAdvancedMode: false,
    isReadOnlyMode: true,
    screenShotNodeId: undefined,
    newNode: null,
    initPublishPage: false,
    publishStatus: {
        isLoading: false,
        isCompleted: false
    },
    isCopiedContent: false,
    isClearElements: false,
}
export const AppStorage = createSlice({
    name: "app",
    initialState,
    reducers: {
        changePreview: (state, action) => {
            state.preview = action.payload;
        },
        addLink: (state, action) => {
            state.links.push(action.payload);
        },
        removeLink: (state, action) => {
            state.links.splice(action.payload, 1);
        },
        changeTitle: (state, action) => {
            state.title = action.payload;
        },
        changeType: (state, action) => {
            state.type = action.payload;
        },
        changePageId: (state, action) => {
            state.pageId = action.payload;
        },
        changeIsMobileDisplayMode: (state, action) => {
            state.isMobileDisplayMode = action.payload;
        },
        changeIsMobileViewDimension: (state, action) => {
            state.isMobileViewDimension = action.payload;
        },
        changeIsExportState: (state, action) => {
            state.isExportState = action.payload;
        },
        changeIsPublishPageState: (state, action) => {
            state.isPublishPageState = action.payload;
        },
        changeIsAdvancedMode: (state, action) => {
            state.isAdvancedMode = action.payload;
        },
        changeIsReadOnlyMode: (state, action) => {
            state.isReadOnlyMode = action.payload;
        },
        changeScreenShotNodeId: (state, action) => {
            state.screenShotNodeId = action.payload;
        },
        toggleShowHiddenBlocks: (state) => {
            state.showHiddenBlocks = !state.showHiddenBlocks;
        },
        changeUser: (state, action) => {
            state.user = action.payload;
        },
        changeNewNode: (state, action) => {
            state.newNode = action.payload
        },
        setInitPublishPage: (state, action) => {
            state.initPublishPage = action.payload
        },
        changePublishStatus: (state, action) => {
            state.publishStatus = {isLoading: action.payload.isLoading, isCompleted: action.payload.isCompleted}
        },
        setCopiedContent: (state, action) => {
            state.isCopiedContent = action.payload
        },
        setIsClearElements: (state, action) => {
            state.isClearElements = action.payload
        }
    },
});
export const {
    changePreview,
    addLink,
    removeLink,
    changePageId,
    changeTitle,
    changeType,
    changeIsMobileDisplayMode,
    changeIsExportState,
    changeIsPublishPageState,
    changeIsMobileViewDimension,
    changeIsAdvancedMode,
    changeIsReadOnlyMode,
    toggleShowHiddenBlocks,
    changeUser,
    changeScreenShotNodeId,
    changeNewNode,
    setInitPublishPage,
    changePublishStatus,
    setCopiedContent,
    setIsClearElements
} = AppStorage.actions;
export default AppStorage.reducer;
