import { useRef, useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {changeActiveModalNode} from "../reducers/node-modal";
import {
    changeIsAdvancedMode,
    changeIsExportState,
    changeIsPublishPageState,
    changePreview,
    toggleShowHiddenBlocks
} from "../reducers/app";
import {deleteNode, setNodes} from "../reducers/nodes";
import {useMutation} from "@apollo/client";
import {DELETE_PAGE, DUPLICATE_PAGE} from "../apollo/Mutations";
import {NodeType} from "../types/PageNode";
import {RequestPageType} from "../types/RequestPageType";

export const useDropdown = () => {
    const dropdownRef = useRef();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch();


    const handleClickOutside = (e) => {
        if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownOpen]);

    const handleDropdownToggle = () => {
        if (!isDropdownOpen) {
            dispatch(changeActiveModalNode(null));
        }
        setIsDropdownOpen(!isDropdownOpen);
    };

    return { dropdownRef, isDropdownOpen, handleDropdownToggle };
};

export const useScale = () => {
    const [zoom, setZoom] = useState(() => Math.round(window.devicePixelRatio * 100));

    useEffect(() => {
        const handleResize = () => {
            setZoom(Math.round(window.devicePixelRatio * 100));
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return { zoom };
};

export const useClickHandlers = (dispatch) => {
    const [duplicatePage] = useMutation(DUPLICATE_PAGE);
    const pageId = useSelector((state) => state.appStorage.pageId)
    const handlerClickExport = () => {
        const blockContent = document.getElementById("blockContent1")
        if (blockContent !== null) {
            dispatch(changePreview(true))
            dispatch(changeIsExportState(true))
        } else {
            alert("Nothing to copy! Create something");
        }
    };
    const handlerClickPublish = () => {
        const blockContent = document.getElementById("blockContent1")
        if (blockContent !== null) {
            dispatch(changePreview(true))
            dispatch(changeIsPublishPageState(true))
        } else {
            alert("Nothing to copy! Create something");
        }
    };

    const handleClickDuplicate = () => {

        duplicatePage({
            variables: {
                id: parseInt(pageId),
                isMeQr: window.self === window.top ? false : true
            }
        }).then(() => {
            alert('Page Duplicated')
        }).catch((e) => {
            alert(e);
        });
    }

    return { handlerClickExport, handlerClickPublish, handleClickDuplicate };
};

export const useAdditionalHandlers = (dispatch, previewState, nodes) => {
    const [deletePage] = useMutation(DELETE_PAGE);
    const pageId = useSelector((state) => state.appStorage.pageId)
    const type = useSelector(state => state.appStorage.type)
    const handlePlayClick = () => {
        if (previewState) {
            dispatch(changePreview(false));
        } else {
            dispatch(changePreview(true));
        }
    };

    const changeIsAdvancedModeAction = (checked) => {
        dispatch(changeIsAdvancedMode(checked));
    };

    const confirmActionAndRedirect = (event) => {
        event.preventDefault();
        const confirmation = window.confirm('Are you sure you want to delete this page?');

        if (confirmation) {
            deletePage({
                variables: {
                    id: parseInt(pageId),
                }
            }).then(() => {
                alert('Deleted page')
            }).catch((e) => {
                console.error(e);
            });
            // window.location = process.env.REACT_APP_BACKEND_ADDRESS + "/domain/";
            window.location = process.env.REACT_APP_NEW_ADMIN + "/projects/";

        }
    };

    const handleDelete = () => {
        let newRootNode = {
            breadCrumbTitle: "Window",
            type: NodeType.PageNode,
            id: '1',
            parentId: null,
            tagName: 'div',
            className: null,
            nodes: [],
            classes: []
        }
        if (type === RequestPageType.MobilePage) {
            newRootNode.customStyle = {'max-width': '440px', 'margin': '0px auto'}
        }
        dispatch(deleteNode({ removeNode: { ...nodes[0], nodes: [] } }));
        dispatch(setNodes({nodes: [newRootNode]}))
    };

    const handleShowHiddenBlocksClick = () => {
        dispatch(toggleShowHiddenBlocks());
    };


    return { handlePlayClick, changeIsAdvancedModeAction, confirmActionAndRedirect, handleDelete, handleShowHiddenBlocksClick };


};

export const simulateClickOnChat = () => {
   const iframe = document.querySelector('iframe[name="helpcrunch-iframe"]');
   if (!iframe) {
     return;
   }
   const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
   const chatButton = iframeDocument.querySelector('.hcw-widget-button-label');
   if (chatButton) {
     chatButton.click();
   }
 }