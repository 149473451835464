import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNewIdForChildNodes,
    addNodeAfterActiveNode,
    addNodeToActiveNode,
    changeDataBSTargetId,
    checkOnReadyMadeBlockLimit,
    findTopParent
} from "../../../service/NodeService";
import {Modal} from "bootstrap";
import {RootState} from "../../../store";
import {PageNode} from "../../../types/PageNode";
import {GraphTemplate} from "../../../graphql/types";
import {changeNewNode} from "../../../reducers/app";
import { changeActiveModalNode } from '../../../reducers/node-modal';
import { changeNode } from '../../../reducers/nodes';
import { CustomStyleField } from '../../../types/CustomStyleField';
import { setShowUpgradeModal } from '../../../reducers/modalSlice';
import { useUserQuery } from '../../../service/User/UserService.hooks';
import { changeNodeSettings } from '../modals/GlobalSettingsModal';

interface IncomeProps {
    template: GraphTemplate,
}

const CommonTemplate: React.FC<IncomeProps> = ({template}) => {
    const activeNode = useSelector((state:RootState) => state.nodeModal.value)
    const dispatch = useDispatch();
    const srcImage = process.env.REACT_APP_IMAGE_ADDRESS + template.img
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const {data: userData} = useUserQuery()

    return <>
        <div key={template.id} className='col-lg-3 col-6 ps-1 p-1 mb-2' data-bs-dismiss="modal"
             onClick={async () => {
                if(checkOnReadyMadeBlockLimit(nodes, userData)){
                    dispatch(setShowUpgradeModal(true))
                    Modal.getOrCreateInstance('#container-to-add-modal').hide();
                    dispatch(changeActiveModalNode(null))
                    return
                }
                try{
                    let nodeToAdd:PageNode = JSON.parse(template.content);
                    const globalStyles = nodes[0]?.customStyle || {};
                    const updatedNodeToAdd: PageNode =  changeNodeSettings({...nodeToAdd}, globalStyles?.fontFamily, globalStyles?.color, globalStyles?.fontSize);
                    updatedNodeToAdd.nodes = updatedNodeToAdd.nodes.map((childNode: PageNode) => changeNodeSettings(childNode, globalStyles?.fontFamily, globalStyles?.color, globalStyles?.fontSize));

                    updatedNodeToAdd.template = {id: template.id,type:template.type,isCommon:true}
                    if (activeNode?.parentId === '1') {
                         const idArray = addNewIdForChildNodes(updatedNodeToAdd, '1');
                         changeDataBSTargetId(updatedNodeToAdd, idArray)
                        addNodeAfterActiveNode(dispatch, activeNode, updatedNodeToAdd, nodes)
                    } else if (activeNode?.parentId === null) {
                        const idArray = addNewIdForChildNodes(updatedNodeToAdd);
                        changeDataBSTargetId(updatedNodeToAdd, idArray)
                        addNodeToActiveNode(dispatch, nodes[0], updatedNodeToAdd);
                    } else {
                        const idArray = addNewIdForChildNodes(updatedNodeToAdd);
                        changeDataBSTargetId(updatedNodeToAdd, idArray)
                        if (activeNode?.className?.includes("container-slide")) {
                            const node = {...activeNode, customStyle: {...activeNode.customStyle, [CustomStyleField.Height]: undefined}}
                            await dispatch(changeNode({ editedNode: node }))
                            addNodeToActiveNode(dispatch, activeNode, updatedNodeToAdd);
                        } else {
                            const topParent = findTopParent(activeNode.parentId, nodes[0].nodes);
                            addNodeAfterActiveNode(dispatch, topParent, updatedNodeToAdd, nodes);
                        }
                    }
                    dispatch(changeNewNode(updatedNodeToAdd))
                    Modal.getOrCreateInstance('#edit-block-modal').hide();
                    dispatch(changeActiveModalNode(null))
                }catch (error){
                    console.error(error)
                }    
             }}>
            <div className="blue-hover rounded-2 h-100 d-flex align-items-start justify-content-center">
                <img className={"w-100 rounded-2"} src={srcImage} alt={'heading block'}/>
            </div>
        </div>
    </>
}


export default CommonTemplate
