import React, {useState} from 'react';
import {changeIsMobileDisplayMode, changePreview} from "../../reducers/app";
import {handlerCopy, pasteCopyNode} from "../../service/EditBarService";
import {deleteNode} from "../../reducers/nodes";
import {changeActiveModalNode} from "../../reducers/node-modal";
import {simulateClickOnChat, useAdditionalHandlers, useClickHandlers} from "../../service/desktopNavigationService";
import {useSelector} from "react-redux";
import {openModal} from "../../reducers/modalSlice";
import { useGetMeQuery } from '../../service/User/UserService.hooks';
import { UserRole } from '../../types/User';
import { setMovementStatus } from "../../reducers/movementSlice";
import UserService from '../../service/User/UserService';
import { PageScreenShotProcessor } from '../../service/ScreenShot/PageScreenShotProcessor';
import PageService from '../../service/Page/PageService';
import { apolloClient } from '../..';
import { useGetPageQuery } from '../../service/Page/PageService.hooks';


const MobileSettingsBar = ({dispatch, getMetaTag, activeNode, isMobileViewDimension, screenShotService}) => {
    const previewState = useSelector((state) => state.appStorage.preview)
    const [checkPreview, setCheckPreview] = useState(previewState)
    const nodes = useSelector((state) => state.nodes.present.value);
    const {handleClickDuplicate, handlerClickExport} = useClickHandlers(dispatch);
    const {handleShowHiddenBlocksClick, confirmActionAndRedirect, handleDelete} = useAdditionalHandlers(dispatch, previewState, nodes);
    const showHiddenBlocks = useSelector((state) => state.appStorage.showHiddenBlocks);
    const isMobileMovement = useSelector((state) => state.movement.isMobile)
    const pageId = useSelector((state) => state.appStorage.pageId);
    const {data: page} = useGetPageQuery({variables: {id: pageId}})
    const {data: user, loading: userLoading} = useGetMeQuery()

    const changeMovementStatus = (state) => {
        if(state === isMobileMovement) return;
        dispatch(setMovementStatus({isMobile: state.target.checked}))
        dispatch(changeActiveModalNode(null))
    }

    const makePageScreenshot = () => {
        screenShotService.runScreenShot(new PageScreenShotProcessor(PageService.getInstance().page.id, apolloClient, PageService.getInstance().page.folder?.id))
        screenShotService.onScreenShotAction().then(result => {
            if (result) {
                screenShotService.onScreenShotReady(result)
            }
        });
    }

    const deleteAllElements = () => {
        handleDelete()
    }

    return (
        <div className="text-start">
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBothOptions"
                    aria-controls="offcanvasWithBothOptions">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-start text-white" data-bs-scroll="true" tabIndex="-1"
                 id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style={{background: "#3E4957"}}>
                <div className="offcanvas-header justify-content-end">
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className={`nav-item ${isMobileViewDimension === false ? 'active' : ''}`}>
                                    <a className="nav-link"
                                       onClick={() => {
                                           dispatch(changeIsMobileDisplayMode(false))
                                           getMetaTag.setAttribute("content", "width=device-width, initial-scale=0")
                                       }
                                       }>
                                        <i className="bi bi-display text-white"></i>
                                    </a>
                                </li>
                                <li className={`nav-item ${isMobileViewDimension === true ? 'active' : ''}`}>
                                    <a className="nav-link"
                                       onClick={() => {
                                           dispatch(changeIsMobileDisplayMode(true))
                                       }}>
                                        <i className="bi bi-phone text-white"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="form-check form-switch text-white" onClick={() => {
                            setCheckPreview(prevCheck => {
                                dispatch(changePreview(!prevCheck))
                                return !prevCheck
                            })
                        }}>
                            <a className="pe-2"><i className="bi bi-play-circle" style={checkPreview ? {color: "blue"} : {color: "white"} }></i></a>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Preview</label>
                        </div>
                    </div>
                    <div>
                        {!userLoading && user.me.roles.includes(UserRole.ROLE_ADMIN) &&
                        <div className="mt-3 d-flex">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox"
                                   onChange={(state) => changeMovementStatus(state)}
                                   checked={isMobileMovement}/>
                            </div>
                            <p className='text-white'>Change Only Mobile</p>
                        </div>}
                        <div className="mt-3">
                            <h6 className="text-secondary">Page</h6>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item mt-1">
                                <a className="nav-link" href={`${process.env.REACT_APP_NEW_ADMIN}/page/create?type=1${page?.page?.folder?.id ? `&parentFolder=${page?.page.folder?.id}` : ""}&projectId=${page?.page.domain?.id}`}>
                                    <i className="bi bi-file-earmark me-2"></i>New Page
                                </a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" href={`${process.env.REACT_APP_NEW_ADMIN}/projects`}>
                                    <i className="bi bi-files me-2"></i>My Projects</a>
                            </li>
                            <li className="nav-item mt-1"><a className="nav-link" href="#"
                                                             onClick={handlerClickExport}>
                                <i className="bi bi-reply me-2"></i>Export</a>
                            </li>
                            <li className="nav-item mt-1"><a className="nav-link" href="#" onClick={() => {
                                dispatch(openModal({modalName: "css-link-modal"}));
                            }}>
                                <i className="bi bi-code-slash pe-2"></i> CSS Link</a>
                            </li>
                            {UserService.getInstance().user.roles.includes(UserRole.ROLE_ADMIN) && <li className="nav-item mt-1">
                                <span className="nav-link" onClick={() => {makePageScreenshot()}}>
                                    <i className="bi bi-camera pe-2"></i> Post ScreenShot
                                </span>
                            </li>}
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas" onClick={handleClickDuplicate}><i
                                    className="bi bi-file-earmark-plus"></i> Duplicate Page</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"
                                   onClick={(event) => confirmActionAndRedirect(event)}>
                                    <i className="bi bi-trash"></i> Delete Page</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="mt-3">
                            <h6 className="text-secondary">EDIT</h6>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas" onClick={() => {
                                    handlerCopy(activeNode)
                                }}><i className="bi bi-files"></i> Copy</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-file-earmark-check"></i> Select All</a>
                            </li>
                            <li className="nav-item mt-1">
                                {activeNode ?
                                    (<a className="nav-link" data-bs-dismiss="offcanvas" onClick={() => {
                                        dispatch(deleteNode({removeNode: activeNode}))
                                        dispatch(changeActiveModalNode(undefined));
                                        handlerCopy(activeNode)
                                    }}><i className="bi bi-scissors"></i> Cut</a>) :
                                    (<a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-scissors"></i> Cut</a>)
                                }
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas" onClick={() => {
                                    pasteCopyNode(dispatch, activeNode)
                                }}><i className="bi bi-clipboard-check"></i> Paste</a>
                            </li>
                            <li className="nav-item mt-1"><a className="nav-link" href="#" onClick={deleteAllElements}>
                                <i className="bi bi-x-circle pe-2"></i> Clear All Elements</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="mt-3">
                            <h6 className="text-secondary">MAIN</h6>
                        </div>
                        <ul className="navbar-nav">
                            <li onClick={simulateClickOnChat} className="nav-item mt-1">
                                <a className="nav-link" data-bs-dismiss="offcanvas"><i className="bi bi-question-circle"></i> Help</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" href={`${process.env.REACT_APP_NEW_ADMIN}/profile`}><i className="bi bi-person"></i> Account</a>
                            </li>
                            <li className="nav-item mt-1">
                                <a className="nav-link" href={`${window.location.origin}/logout`}><i className="bi bi-box-arrow-right"></i> Log Out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileSettingsBar