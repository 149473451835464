import React, { useEffect } from 'react';
import {PageNode} from "../../../types/PageNode";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { changeNode } from '../../../reducers/nodes';

interface TextBlockProperty {
    nodeToShow: PageNode,
    styleObject: any,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    isMobileViewDimension: boolean,
    className: string,
    isPreviewState: boolean,
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const TextBlock: React.FC<TextBlockProperty> = ({
                                                           nodeToShow,
                                                           styleObject,
                                                           handleClick,
                                                           className,
                                                           handleDoubleClick,
                                                           isPreviewState
                                                       }) => {

    const mainNode: PageNode = useSelector((state: RootState) => state.nodes.present.value[0]);
    const dispatch = useDispatch()

    let pcValue: string
    if (isPreviewState) {
        pcValue = nodeToShow.value ?? ''
    } else {
        pcValue = nodeToShow.value ?? 'Add your custom text'
    }

    const styles = generateStyles(nodeToShow);
    const mobileStyles = generateMobileStyles(nodeToShow);

    function updateInlineStyles(value: string) {
        let updatedValue = value
        const tempElement = document.createElement('div');
        tempElement.innerHTML = value;
        const paragraph = tempElement.querySelector('p');
        
        if(paragraph){
            const combinedStyles = `font-family: ${mainNode?.customStyle?.fontFamily || ""}; font-size: ${mainNode?.customStyle?.fontSize || ""}; color: ${mainNode?.customStyle?.color || ""};`;
            paragraph.setAttribute('style', combinedStyles);
            return tempElement.innerHTML;
        }

        if(mainNode?.customStyle?.fontFamily){
          updatedValue = updatedValue?.replace(
              /font-family:\s*[^;"]+/g, 
              `font-family: '${mainNode?.customStyle?.fontFamily}'`
            )
        }
        if(mainNode?.customStyle?.color){
          updatedValue = updatedValue?.replace(
              /color:\s*[^;"]+/g, 
              `color: ${mainNode?.customStyle?.color}`
            )
        }
        if(mainNode?.customStyle?.fontSize){
          updatedValue = updatedValue?.replace(
              /font-size:\s*[^;"]+/g, 
              `font-size: ${mainNode?.customStyle?.fontSize}`
            )
        }
        return updatedValue
    }

    useEffect(() => {
        const updatedValue = updateInlineStyles(nodeToShow.value);
        dispatch(changeNode({nodeToShow, editedNode: {...nodeToShow, value: updatedValue}}));
    }, [mainNode.customStyle])

    return (
        <>  
            {(styles || mobileStyles) ? <style>{styles} {mobileStyles}</style> : ''}
            <div
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                id={`blockContent${nodeToShow.id}`}
                className={`${className} d-block`}
                style={styleObject}
                dangerouslySetInnerHTML={{ __html: pcValue }}
            ></div>
        </>
    )
};

export default TextBlock;

export const generateMobileStyles = (nodeToShow: PageNode): string => {
    if (
        !nodeToShow.isMobileTextEnabled || 
        !nodeToShow.textMobileClasses || 
        Object.keys(nodeToShow.textMobileClasses).length === 0
    ) {
        return '';
    }
    return `
        @media (max-width: 576px) {
            ${Object.entries(nodeToShow.textMobileClasses)
                .map(
                    ([key, value]) =>
                        `#blockContent${nodeToShow.id} .${key} { ${value } }`
                )
                .join('\n')}
        }
    `;
};

export const generateStyles = (nodeToShow: PageNode): string => {
    if ( 
        !nodeToShow.textClasses || 
        Object.keys(nodeToShow.textClasses).length === 0
    ) {
        return '';
    }
    return `
            ${Object.entries(nodeToShow.textClasses)
                .map(
                    ([key, value]) =>
                        `#blockContent${nodeToShow.id} .${key} { ${value} }`
                )
                .join('\n')}
    `;
};
