import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNewIdForChildNodes,
    addNodeAfterActiveNode,
    addNodeToActiveNode,
    changeDataBSTargetId,
    checkOnReadyMadeBlockLimit,
    findTopParent
} from "../../../../service/NodeService";
import {useMutation} from "@apollo/client";
import {TEMPLATE_LIST} from "../../../../apollo/Queries";
import {REMOVE_TEMPLATE} from "../../../../apollo/Mutations";
import {Modal} from "bootstrap";
import {RootState} from "../../../../store";
import {TemplateDimensionType} from "../../../../types/TemplateDimensionType";
import {PageNode} from "../../../../types/PageNode";
import {Template} from "../../../../graphql/types";
import {useUserTemplateListQuery} from "./UserTemplates.hooks";
import {changeNewNode} from "../../../../reducers/app";
import { changeActiveModalNode } from '../../../../reducers/node-modal';
import { CustomStyleField } from '../../../../types/CustomStyleField';
import { changeNode } from '../../../../reducers/nodes';
import { setShowUpgradeModal } from '../../../../reducers/modalSlice';
import { useUserQuery } from '../../../../service/User/UserService.hooks';
import { changeNodeSettings } from '../../modals/GlobalSettingsModal';

// const TMP => () => {}
const UserTemplates = () => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const dispatch = useDispatch();
    const {data: templatesData} = useUserTemplateListQuery();
    const {data: userData} = useUserQuery()
    const type = useSelector((state: RootState) => state.appStorage.type)
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const [addTemplate] = useMutation(REMOVE_TEMPLATE, {refetchQueries: [{query: TEMPLATE_LIST}]});
    const removeTemplateAction = (id: number): void => {
        addTemplate({
            variables: {
                id: id
            }
        }).then(() => alert('Template Removed')).catch((e) => {
            alert(e);
        });
    }

    return <>
        {(templatesData && templatesData.templateList) ?
            templatesData.templateList.map((template: Template) => (
                template.type === type || template.type === TemplateDimensionType.CommonTemplate ?
                <div key={template.id} className={'mb-2 blue-hover rounded-2 user-template-container'}>
                    <div className={'user-template-cross text-center rounded-5 position-absolute end-0 top-0 d-flex justify-content-center align-items-center'}
                         onClick={() => {
                             removeTemplateAction(template.id);
                         }}>
                        <button className='btn-close'></button>
                    </div>
                    <div className='h-100 w-100 d-flex align-items-center justify-content-center'
                         data-bs-dismiss="modal"
                         onClick={async () => {
                            if(checkOnReadyMadeBlockLimit(nodes, userData)){
                                dispatch(setShowUpgradeModal(true))
                                Modal.getOrCreateInstance('#container-to-add-modal').hide();
                                dispatch(changeActiveModalNode(null))
                                return
                            }
                             let nodeToAdd: PageNode = JSON.parse(template.content);
                             const globalStyles = nodes[0]?.customStyle || {};
                             const updatedNodeToAdd: PageNode =  changeNodeSettings({...nodeToAdd}, globalStyles?.fontFamily, globalStyles?.color, globalStyles?.fontSize);
                             updatedNodeToAdd.nodes = updatedNodeToAdd.nodes.map((childNode: PageNode) => changeNodeSettings(childNode, globalStyles?.fontFamily, globalStyles?.color, globalStyles?.fontSize));

                             updatedNodeToAdd.template = {id: template.id,type:template.type,isCommon:false}
                            if (activeNode?.parentId === '1') {
                                const idArray = addNewIdForChildNodes(updatedNodeToAdd, '1');
                                changeDataBSTargetId(updatedNodeToAdd, idArray)
                                 addNodeAfterActiveNode(dispatch, activeNode, updatedNodeToAdd, nodes);
                            } else if (activeNode?.parentId === null) {
                                const idArray = addNewIdForChildNodes(updatedNodeToAdd);
                                changeDataBSTargetId(updatedNodeToAdd, idArray)
                                addNodeToActiveNode(dispatch, nodes[0], updatedNodeToAdd);
                            } else {
                                const idArray = addNewIdForChildNodes(updatedNodeToAdd);
                                changeDataBSTargetId(updatedNodeToAdd, idArray)
                                if (activeNode?.className?.includes("container-slide")) {
                                    const node = {...activeNode, customStyle: {...activeNode.customStyle, [CustomStyleField.Height]: undefined}}
                                    await dispatch(changeNode({ editedNode: node }))
                                    addNodeToActiveNode(dispatch, activeNode, updatedNodeToAdd);
                                } else {
                                    const topParent = findTopParent(activeNode.parentId, nodes[0].nodes);
                                    addNodeAfterActiveNode(dispatch, topParent, updatedNodeToAdd, nodes)
                                }  
                            }
                             dispatch(changeNewNode(updatedNodeToAdd))
                             Modal.getOrCreateInstance('#edit-block-modal').hide();
                             dispatch(changeActiveModalNode(null));
                         }}
                    >
                        <img className={'user-template-sub-container'} src={process.env.REACT_APP_IMAGE_ADDRESS + template.img} alt={'heading block'}/>
                    </div>
                </div> : <></>
            )) : <></>
        }
    </>
}

export default UserTemplates
