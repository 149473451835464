import {
    addNode,
    changeNodeHidden,
    deleteNode,
    findNode,
    moveBack,
    moveForward,
    updateRootNode
} from "../reducers/nodes";
import {changeActiveModalNode} from "../reducers/node-modal";
import {addNewIdForChildNodes, addNodeToActiveNodeWithAutoSelect, changeDataBSTargetId, getNodeWithNewIds} from "./NodeService";
import {DivRowNode, NodeType, PageNode} from "../types/PageNode";
import { ActiveTab, setActiveTab } from "../reducers/active-tab";
import DivSliderNodeFactory from "./Node/Factory/DivSliderNodeFactory";

const parentExistsFunc = (searchNode: PageNode, nodes: PageNode[], parentExists: boolean, elements: any) => {
    while (parentExists) {
        if (searchNode && searchNode.parentId != '1') {
            let parentNode = findNode(nodes[0], searchNode.parentId)
            if (parentNode === null) {
                parentExists = false
            } else {
                elements.unshift(parentNode);
                searchNode = parentNode;
            }
        } else {
            parentExists = false;
        }
    }
    return elements
}

const moveForwardAction = (dispatch: any, activeNode: PageNode, nodes: any, isMobileMovement: boolean) => {
    let nodeToAction = findElementWhoseParentWhichHaveMoreThanOneNode(activeNode, nodes[0]);
    if (nodeToAction !== null) {
        dispatch(moveForward({node: nodeToAction, isMobile: isMobileMovement}));
    }
}

const moveBackAction = (dispatch: any, activeNode: PageNode, nodes: any, isMobileMovement: boolean) => {
    let nodeToAction = findElementWhoseParentWhichHaveMoreThanOneNode(activeNode, nodes[0]);
    if (nodeToAction !== null) {
        dispatch(moveBack({node: nodeToAction, isMobile: isMobileMovement}));
    }
}

export const findElementWhoseParentWhichHaveMoreThanOneNode = (node: PageNode, nodes: PageNode[]): PageNode | null => {
    if (node.id !== '1') {
        let parentNode = findNode(nodes, node.parentId);

        if (parentNode.nodes.length > 1 || parentNode.id=='1') {
            return node;
        } else {
            return findElementWhoseParentWhichHaveMoreThanOneNode(parentNode, nodes);
        }
    } else {
        return null;
    }
}
const findNodeWhichAreContainer = (node: PageNode, nodes: PageNode[], className: string = null): DivRowNode | null => {
    if (node.parentId != '1') {
        if ((className === null && node.tagName === 'div') || (className !== null && node.className === className)) {
            return node as DivRowNode;
        } else {
            let parentNode = findNode(nodes, node.parentId);
            return findNodeWhichAreContainer(parentNode, nodes,className);
        }
    } else {
        return null;
    }
}


const handlerCopy = (activeNode: PageNode) => {
    navigator.clipboard.writeText(JSON.stringify(activeNode)).then()
}
export const duplicateNode = (activeNode: PageNode, nodes: PageNode[]) => {
    //TODO: Add duplicate
    let parentNode: PageNode = findNode(nodes, activeNode.parentId)
    parentNode.nodes.push()
}


const pasteCopyNode = (dispatch: any, activeNode: PageNode) => {
    const div = document.querySelector(`#blockContent${activeNode.id}`).outerHTML
    if(div.endsWith(`/${activeNode.tagName}>`)) {
        navigator.clipboard.readText()
            .then(text => {
                let newNode = JSON.parse(text);
                newNode.id = Date.now();
                const idArray = addNewIdForChildNodes(newNode);
                changeDataBSTargetId(newNode, idArray)
                dispatch(addNode({activeNode, newNode}));
                dispatch(changeActiveModalNode(newNode));
            })
    }
}

const updateNodesRecursively = (oldNode: PageNode, newNode: PageNode, parentNode: PageNode): PageNode => {
    if (oldNode.id === parentNode.id) {
        return newNode;
    }

    return {
        ...oldNode,
        nodes: oldNode.nodes.map((node) =>
            updateNodesRecursively(node, newNode, parentNode)
        ),
    };
}
const pasteCopyNodeAbove = (dispatch: any, activeNode: PageNode, nodes: PageNode[]) => {
    navigator.clipboard.readText().then((text) => {
        let newNode = JSON.parse(text);
        newNode.id = Date.now();
        const idArray = addNewIdForChildNodes(newNode);
        changeDataBSTargetId(newNode, idArray)
        newNode.parentId = activeNode.parentId;

        const parentNode = findNode(nodes[0], activeNode.parentId);
        const activeNodeIndex = parentNode.nodes.findIndex(
            (node: PageNode) => node.id === activeNode.id
        );

        const updatedParentNode = {
            ...parentNode,
            nodes: parentNode.nodes.slice()
        };
        updatedParentNode.nodes.splice(activeNodeIndex, 0, newNode);

        const updatedRootNode = updateNodesRecursively(nodes[0], updatedParentNode, parentNode);

        dispatch(updateRootNode({ newRootNode: updatedRootNode }));
        dispatch(changeActiveModalNode(newNode));
    });
};

const pasteCopyNodeBelow = (dispatch: any, activeNode: PageNode, nodes: PageNode[]) => {
    navigator.clipboard.readText().then((text) => {
        let newNode = JSON.parse(text);
        newNode.id = Date.now();
        const idArray = addNewIdForChildNodes(newNode);
        changeDataBSTargetId(newNode, idArray)
        newNode.parentId = activeNode.parentId;

        const parentNode = findNode(nodes[0], activeNode.parentId);
        const activeNodeIndex = parentNode.nodes.findIndex(
            (node: PageNode) => node.id === activeNode.id
        );

        const updatedParentNode = {
            ...parentNode,
            nodes: parentNode.nodes.slice()
        };
        updatedParentNode.nodes.splice(activeNodeIndex + 1, 0, newNode);

        const updatedRootNode = updateNodesRecursively(nodes[0], updatedParentNode, parentNode);

        dispatch(updateRootNode({ newRootNode: updatedRootNode }));
        dispatch(changeActiveModalNode(newNode));
    });
}

const activeParentNode = (activeNode: PageNode, nodes: PageNode[], dispatch: any) => {
    let parentNode = findNode(nodes[0], activeNode.parentId);
    return new Promise((resolve: any) => {
        dispatch(changeActiveModalNode(parentNode));
        resolve();
    });
};


const editBarHotkeys = (func: any, activeNode: PageNode, dispatch: any, nodes: PageNode[]) => {
    func("ctrl+c, cmd+c", () => {handlerCopy(activeNode)})
    func("ctrl+v, cmd+v", () => {
        if (!activeNode.disallowedAddElements) {
            activeParentNode(activeNode, nodes, dispatch)
                .then(() => pasteCopyNode(dispatch, activeNode))
        } else {
            pasteCopyNode(dispatch, activeNode)
        }
    });
    func("ctrl+x, cmd+x", () => {
        dispatch(deleteNode({removeNode: activeNode}))
        dispatch(changeActiveModalNode(undefined));
        handlerCopy(activeNode)
    })
    func("del, backspace", (async() => {
        dispatch(changeActiveModalNode(null))
        await dispatch(deleteNode({removeNode: activeNode}))
        updateActiveSlide(nodes[0], activeNode.parentId)
        }))
}


const changeHidden = (nodeState:PageNode, setNodeState:any, dispatch:any) => {
    let tmpNode = { ...nodeState };
    tmpNode.hidden = tmpNode.hidden === 'desktopHidden' ? '': 'desktopHidden';
    setNodeState(tmpNode);
    dispatch(changeNodeHidden({ id: tmpNode.id, type: 'hidden', hidden: tmpNode.hidden }));
};

const changeHiddenMobile = (nodeState:PageNode, setNodeState:any, dispatch:any) => {
    let tmpNode = { ...nodeState };
    tmpNode.hidden = tmpNode.hidden === 'mobileHidden' ? '': 'mobileHidden';
    setNodeState(tmpNode);
    dispatch(changeNodeHidden({ id: tmpNode.id, type: 'hidden', hidden: tmpNode.hidden }));
};

const parentPlusButtonClick = (
    dispatch:any,
    activeContainerNode:PageNode,
    Modal:any,
    modalSelector:any
) => {
    dispatch(changeActiveModalNode(activeContainerNode));
    Modal.getOrCreateInstance(modalSelector).show();
    dispatch(setActiveTab(ActiveTab.CONTENT))
};

const addSlideButtonClick = async(
    dispatch:any,
    activeNode:PageNode,
) => {
    const tmpNode = DivSliderNodeFactory.getNewSlideNode(activeNode);
    await addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...tmpNode});

    const buttons = document.querySelector(`#blockContent${activeNode.id}`)
    ?.querySelector(".carousel-indicators")
    ?.querySelectorAll("button");
    if (buttons && buttons.length > 0) {
        buttons[buttons.length - 1].click();
    }
};

const updateActiveSlide = async(
    node: PageNode,
    parentId: string,
) => {
    const element = findNode(node, parentId);
    if (element.type !== NodeType.SliderNode) return;
    const blockContent = document.querySelector(`#blockContent${element.id}`);
  
    if (blockContent) {
        const buttons = blockContent
            .querySelector(".carousel-indicators")
            ?.querySelectorAll("button");
        const slides = blockContent.querySelectorAll(".carousel-item");

        if (buttons && buttons.length > 0) {
            buttons[buttons.length - 1].classList.add("active");
        }

        if (slides && slides.length > 0) {
            slides[slides.length - 1].classList.add("active");
        }
    }
};

const handleDuplicateContent = (dispatch:any, activeNode:PageNode,) => {
    let copyOfActive = {...activeNode}
    copyOfActive = getNodeWithNewIds(copyOfActive)
    dispatch(addNode({
        activeNode: {id: activeNode.parentId},
        newNode: copyOfActive
    }))
}

function isLightColor(color: string): boolean {
    if (color.includes("gradient")) {
        const cleanedGradient = color.split(' repeat')[0].replace(/^rgba\(0, 0, 0, 0\)\s*/, '');
        const colorMatches = cleanedGradient.match(/(rgb[a]?\([\d\s,.]+\)|#[0-9a-fA-F]{3,8})/g);
        if (colorMatches) {
            color = colorMatches.length > 1 ? colorMatches[1] : colorMatches[0];
        } else {
            return true;
        }
    }

    const rgbValues = color.match(/\d+/g);
    if (!rgbValues || rgbValues.length < 3) return true;
    const [r, g, b] = rgbValues.map(Number);
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
    return brightness > 128;
}

function findNearestLightBackground(element: HTMLElement | null): boolean {
    let parent = element?.parentElement;
    while (parent) {
        const bgColor = getComputedStyle(parent).background;
        if ((bgColor && !bgColor.includes("transparent") && !bgColor.includes("rgba(0, 0, 0, 0)")) || bgColor.includes("gradient")) {
            return isLightColor(bgColor);
        }
        parent = parent.parentElement;
    }
    return true;
}

export {
    moveForwardAction,
    moveBackAction,
    handlerCopy,
    pasteCopyNode,
    parentExistsFunc,
    editBarHotkeys,
    findNodeWhichAreContainer,
    pasteCopyNodeAbove,
    pasteCopyNodeBelow,
    activeParentNode,
    changeHidden,
    changeHiddenMobile,
    parentPlusButtonClick,
    addSlideButtonClick,
    updateActiveSlide,
    handleDuplicateContent,
    findNearestLightBackground
}