import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./store";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import LinkProvider from "./service/Apollo/LinkProvider";
import QueryService from "./service/QueryService";
import {ServiceContainer} from "./service/ServiceContainer";
import {ScreenShotService} from "./service/ScreenShot/ScreenShotService";
import { hotjar } from "react-hotjar";
import GlobalWidgets from './components/widgets/GlobalWidgets';

const queryService = new QueryService()
const screenShotService = new ScreenShotService(store.dispatch)
// const fileManagerService = new FileManagerService(store.dispatch)
ServiceContainer.register(queryService, 'QueryService')
ServiceContainer.register(screenShotService, 'ScreenShotService')
// ServiceContainer.register(fileManagerService, 'FileManagerService')

const root = ReactDOM.createRoot(document.getElementById('root'));
export const apolloClient = new ApolloClient({
	link: LinkProvider.getCurrentLink(),
    cache: new InMemoryCache(),
});

hotjar.initialize({ id: 5060082, sv: 6 });

root.render(
    <Provider store={store}>
		<ApolloProvider client={apolloClient}>
				<GlobalWidgets />
				<App queryService={queryService} screenShotService={screenShotService} apolloClient={apolloClient}/>
		</ApolloProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();