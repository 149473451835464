import React, { BaseSyntheticEvent, FC } from "react";
import laptop from "./laptop.webp";
import copy from "./copy-icon.svg";
import { useGetPageQuery } from "../../../service/Page/PageService.hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

type Props = {
  show: boolean;
  onCancel?: () => void;
};

export const PublishModal: FC<Props> = ({ show, onCancel }) => {
  const publishModal = useSelector(
    (state: RootState) => state.modal.publishModal
  );

  const { data: page } = useGetPageQuery({
    skip: !publishModal.pageId,
    variables: { id: publishModal.pageId },
  });

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(page?.page.publicUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const openPage = () => {
    if (page?.page) window.location.href = page?.page.publicUrl;
  };

  const openMyProjects = () => {
    window.location.href = `${process.env.REACT_APP_NEW_ADMIN}/projects`;
    onCancel();
  };

  const openCreateQr = () => {
    window.location.href = `${process.env.REACT_APP_NEW_ADMIN}/entry/${publishModal.pageId}`;
    onCancel();
  };

  const openShareSettings = () => {
    window.location.href = `${process.env.REACT_APP_NEW_ADMIN}/user/access/page/${publishModal.pageId}/access`;
    onCancel();
  };

  const closeByBackground = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    if (e.target.id === "confirmModal") onCancel();
  };

  if (window.self !== window.top) return;

  return (
    <div
      id="confirmModal"
      className={`modal ${
        show ? "show d-flex" : ""
      } align-items-center justify-content-center`}
      style={{
        display: show ? "block" : "none",
        background: "rgb(0, 0, 0, 0.6)",
      }}
      onClick={(e) => closeByBackground(e)}
    >
      <div style={{ maxWidth: "500px" }}>
        <div className="modal-content text-break d-flex flex-column align-items-center">
          <div className="col-12 modal-header pb-2 border-0">
            <button
              className="btn-close"
              aria-label="Close"
              onClick={onCancel}
              style={{ height: "16px", width: "16px", opacity: "1" }}
            ></button>
          </div>
          <div className="border-0 text-center d-flex flex-column align-items-center px-4">
            <h4 className="fs-4 text-dark-gray mb-4">
              Your Page Is Published!
            </h4>
            <img className="my-1" src={laptop} alt="laptop" />
            <p className="text-gray-custom mt-4 mb-3">
              Congratulations! Your page is now published and available
              online.Share it with your audience and start making an impact.
            </p>
            <div className="mb-3 col-12 bg-primary-light d-flex align-items-center justify-content-between p-12 rounded-1 border border-primary">
              <p className="m-0 text-primary text-nowrap overflow-hidden col-11 col-md-auto">
                {page?.page.publicUrl.slice(0, 35)}...
              </p>
              <img
                src={copy}
                onClick={copyLink}
                className="cursor-pointer"
                style={{ fontSize: "18px", fill: "#3a83f6" }}
              />
            </div>
            <div className="col-12 border border-gray rounded-1 p-12 px-3 mb-3">
              <p className="fw-bold text-dark-gray m-0 mb-2 mb-md-3">Page Options</p>
              <div className="col-12 d-flex align-items-center justify-content-between px-md-5">
                <div
                  onClick={openCreateQr}
                  className="d-flex align-items-center cursor-pointer"
                >
                  <i
                    className="bi bi-qr-code-scan me-2"
                    style={{ color: "#212529" }}
                  ></i>
                  <p className="fs-7 m-0 text-decoration-underline text-dark-custom">
                    Create QR Code
                  </p>
                </div>
                <div
                  onClick={openShareSettings}
                  className="d-flex align-items-center cursor-pointer"
                >
                  <i
                    className="bi bi-share me-2"
                    style={{ color: "#212529" }}
                  ></i>
                  <p className="fs-7 m-0 text-decoration-underline text-dark-custom">
                    Share Settings
                  </p>
                </div>
              </div>
            </div>
            <p className="text-gray-custom mb-3">
              Need to make changes? You can update your page anytime from the
              constructor.
            </p>
          </div>
          <div
            className="col-12 d-flex flex-column flex-md-row justify-content-between p-12 pt-0"
            style={{ marginTop: "12px" }}
          >
            <button
              onClick={openPage}
              type="button"
              className="publishBtnModal btn btn-outline-primary white-hover fw-normal py-3 mb-2 mb-md-0"
            >
              Open Page
            </button>
            <button
              onClick={openMyProjects}
              type="button"
              className="publishBtnModal btn btn-primary text-white fw-bold py-3"
            >
              My Projects
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
