import React, { useEffect, useRef } from "react";
import FileUploadButton from "./FileUploadButton";
import FileRender from "./FileRender";
import { Modal } from "bootstrap";
import ReactDOM from 'react-dom';
import { FileManagerFile } from "./FileManagerFile";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {UserRole} from "../../types/User";
import { IMeQrStorageData } from "../MeQrStorageService";

interface StylesProps {
    files: FileManagerFile[],
    allowedFileTypes: string[],
    onFileUpload: (file: File) => void;
    onFileDelete: (file: FileManagerFile) => void;
    onFileChoose: (file: FileManagerFile) => void;
    onModalClose: () => void;
    isFullStorage: boolean;
    isUserPremium: boolean;
    fileUploadLoading: boolean;
    meQrStorageData: IMeQrStorageData | null;
}

export const calculateTotalSize = (files: FileManagerFile[]): number => {
    return files.reduce((total, file) => total + file.size, 0);
};

const FileManager: React.FC<StylesProps> = ({
                                                files,
                                                allowedFileTypes,
                                                onFileUpload,
                                                onFileDelete,
                                                onFileChoose,
                                                onModalClose,
                                                isFullStorage,
                                                isUserPremium,
                                                fileUploadLoading,
                                                meQrStorageData
                                            }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const modalRootRef = useRef<HTMLDivElement | null>(null);
    let modalContainerHtml: HTMLDivElement = document.getElementById('file-manager-container') as HTMLDivElement;
    if (!modalContainerHtml) {
        modalContainerHtml = document.createElement('div');
        modalContainerHtml.setAttribute('id', 'file-manager-container');
        document.body.appendChild(modalContainerHtml);
    }
    modalRootRef.current = modalContainerHtml;
    const user = useSelector((state: RootState) => state.appStorage.user);
    const isAdmin = user ? user.roles.includes(UserRole.ROLE_ADMIN) : false;

    useEffect(() => {
        Modal.getOrCreateInstance('#file-manager').show();
        const modalElement = modalRef.current;

        if (modalElement) {
            modalElement.addEventListener('hidden.bs.modal', onModalClose);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('hidden.bs.modal', onModalClose);
            }
        };
    }, [onModalClose]);

    const privateOnFileChoose = (file: FileManagerFile) => {
        Modal.getOrCreateInstance('#file-manager').hide();
        onFileChoose(file);
    };

    const formatFileSize = (size: number) => {
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    };

    const totalSizeInMB = calculateTotalSize(files) / (1024 * 1024);
    const isUploadDisabled = totalSizeInMB > 100;
    const isOverLimit = totalSizeInMB > 100;
    const isFullMeQrStorate = meQrStorageData && meQrStorageData.usedStorageSize > meQrStorageData.storageSize;
    const isLoadingMeQrStorage = window.top !== window.self && !meQrStorageData;

    const isButtonDisabled = window.top === window.self
        ? (isUploadDisabled && !isAdmin && !isUserPremium) || fileUploadLoading
        : isFullMeQrStorate || isLoadingMeQrStorage;

    return ReactDOM.createPortal(
        <>
            <div ref={modalRef} className="modal show modal-lg" id="file-manager" tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h4 className="modal-title">File Manager</h4>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body p-md-4 p-3">
                            <div className="border p-4 rounded-top">
                                <div className='row'>
                                    <div className='col-12 col-md-4 mb-3 mb-md-0'>
                                        <FileUploadButton allowedFileTypes={allowedFileTypes} onSuccess={onFileUpload}
                                        fileUploadLoading={fileUploadLoading || isLoadingMeQrStorage}  disabled={isButtonDisabled}/>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="bg-secondary-light px-3 h-100 d-flex align-items-center rounded py-2">
                                            <p className='mb-0 text-light-blue fs-7'>
                                                File must be less than <b>100 MB</b>. You can only upload files <b>.JPG,
                                                .PNG, .PDF, .DOCX, .SVG, .MP4</b> formats
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {isFullStorage && <div className="text-danger mt-2">Storage size exceeds {meQrStorageData ? formatFileSize(meQrStorageData.storageSize) : "100 MB"}</div>}
                            </div>

                            <div className='p-4 border border-top-0'>
                                <div className='row gy-3 overflow-y-auto file-render-container'>
                                    <FileRender onFileDelete={onFileDelete} onFileChoose={privateOnFileChoose} files={files} />
                                </div>
                            </div>
                            <div className='p-3 border border-top-0'>
                                {window.top === window.self ? (
                                    <>
                                        {!isUserPremium && !isAdmin && (
                                            <div className="bg-main-background position-relative overflow-hidden rounded-4" style={{ height: "8px" }}>
                                                <div
                                                    className={`h-100 ${isOverLimit ? 'bg-danger' : 'bg-blue'} position-absolute translate-middle-y top-50 w-100 rounded-4`}
                                                    style={{ right: totalSizeInMB > 100 ? '0' : `calc(100% - ${totalSizeInMB}%)` }}
                                                ></div>
                                            </div>
                                        )}
                                        <p className="text-background-mobile mb-0 mt-2 fs-7">Used {formatFileSize(calculateTotalSize(files))} {!isUserPremium && !isAdmin && "from 100 MB"}</p>
                                    </>
                                ) : (
                                    meQrStorageData && (
                                        <>
                                            <div className="bg-main-background position-relative overflow-hidden rounded-4" style={{ height: "8px" }}>
                                                <div
                                                    className={`h-100 ${meQrStorageData.usedStorageSize * 100 / meQrStorageData.storageSize > 90 ? 'bg-danger' : 'bg-blue'} position-absolute translate-middle-y top-50 w-100 rounded-4`}
                                                    style={{ right: meQrStorageData.usedStorageSize > meQrStorageData.storageSize ? '0' : `calc(100% - ${meQrStorageData.usedStorageSize * 100 / meQrStorageData.storageSize}%)` }}
                                                ></div>
                                            </div>
                                            <p className="text-background-mobile mb-0 mt-2 fs-7">
                                                Used {formatFileSize(meQrStorageData.usedStorageSize)} from {formatFileSize(meQrStorageData.storageSize)}
                                            </p>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>,
        modalRootRef.current
    );
};

export default FileManager;
