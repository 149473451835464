import React from 'react';
import ContainerSize, { ContainerSizeProperty } from "./ContainerSize";
import BorderSpacing, { StyleSettingsProps } from "./BorderSpacing";
import {
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import EditModalSizeInput, { InputWidthPostfixProperty } from "../ui/input/EditModalSizeInput";
import {CustomStyleField} from "../../../types/CustomStyleField";
import Spacing, { SpacingProperty } from "./Spacing";
import {ButtonNode} from "../../../types/PageNode";
import { ComponentArrayItem, StyleCheckKeys } from '../../../types/StyleCheckKeys';
import useSortedComponents from '../../../hooks/useSortedComponents';
import { MobileStyleRadioButton } from '../ui/MobileStyleRadioButton';

type СomponentItemType = 
| ContainerSizeProperty 
| InputWidthPostfixProperty
| StyleSettingsProps
| SpacingProperty

const sortOrder: StyleCheckKeys[] = [
    StyleCheckKeys.ContainerSize,
    StyleCheckKeys.CornerRadius,
    StyleCheckKeys.Border,
    StyleCheckKeys.Spacing
  ];

export interface UrlSettingsProps {
    isMobileViewDimension: boolean
    editableNode: ButtonNode,
    changeStyleFunction: ChangeStyleFunction,
    changeNodeFunction: ChangeNodeFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction
    changeFormStyleFunction: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    imageSrc: string
}

// Компонент с типизированными входными параметрами
const UrlSettings: React.FC<UrlSettingsProps> = ({
                                                     editableNode,
                                                     isMobileViewDimension,
                                                     changeNodeFunction,
                                                     changeStyleFunction,
                                                     deleteStyleByName,
                                                     imageSrc
                                                 }) => {
    let isMobileStyleContainerSize: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleContainerSize = editableNode.isMobileStyleContainerSize || false
    }

    let componentsArray: ComponentArrayItem<СomponentItemType>[] = [
        {
          condition: true,
          type: StyleCheckKeys.ContainerSize,
          component: ContainerSize,
          props: {
            isMobileStyleContainerSize,
            deleteStyleByName,
            changeNodeFunction,
            changeStyleFunction,
            editableNode,
            imageSrc,
          },
        },
        {
            condition: true,
            type: StyleCheckKeys.CornerRadius,
            component: EditModalSizeInput,
            props: {
                deleteStyleByName,
                changeStyleFunction,
                editableNode,
                optionValue: CustomStyleField.BorderRadius,
                labelName: "Border Radius (px)",
                isMobileStyle: isMobileStyleContainerSize
            },
        },
        {
            condition: true,
            type: StyleCheckKeys.Border,
            component: BorderSpacing,
            props: {
                isMobileViewDimension,
                editableNode,
                changeNodeFunction,
                isMobileStyleContainerSize,
            },
        },
        {
            condition: true,
            type: StyleCheckKeys.Spacing,
            component: Spacing,
            props: {
                isMobileStyleContainerSize,
                deleteStyleByName,
                changeNodeFunction,
                editableNode,
                isMobileViewDimension,
                changeStyleFunction
            },
        },
    ];

    componentsArray = useSortedComponents(componentsArray, sortOrder);

    return (
        <div>
            <div className="row g-3 mt-0">
                <div className="d-flex align-items-center">
                    <h5 className="mt-3 me-auto">Settings</h5>
                    <div className="mt-3">
                    <MobileStyleRadioButton currentStatus={isMobileStyleContainerSize}
                            onChange={(state) => {
                                editableNode.isMobileStyleContainerSize = state;
                                changeNodeFunction(editableNode)
                            }}/>
                    </div>
                </div>
                {componentsArray.map(({ condition, type, component: Component, props }, index) => {
                    if (!condition) return null;
                    if  (type === StyleCheckKeys.ContainerSize || type === StyleCheckKeys.Border) {
                        return <Component {...props} key={index} />
                    }
                    if (type === StyleCheckKeys.Spacing) {
                        return <div className="col-12" key={index}><Component {...props} /></div>
                    }
                    return <div className="col-md-6" key={index}><Component {...props} /></div>;
                })}
            </div>
        </div>
    )
}

export default UrlSettings;